import React from 'react';
import { Stack, Flex } from '@chakra-ui/react';
import { Header, Footer } from 'components/ui';

function Layout({ children, ...styleProps }) {
  return (
    <Flex
      flexDirection="column"
      minHeight="100vh"
      justifyContent="space-between"
    >
      <Header />
      <Stack
        as="main"
        height="100%"
        maxWidth="1200px"
        margin="0 auto"
        {...styleProps}
      >
        {children}
      </Stack>
      <Footer />
    </Flex>
  );
}

export { Layout };

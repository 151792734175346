import React from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
} from '@chakra-ui/react';

function WarningDialog({
  isWarningOpen,
  onWarningClose,
  onConfirm,
  cancelRef,
  children,
}) {
  return (
    <AlertDialog
      isOpen={isWarningOpen}
      leastDestructiveRef={cancelRef}
      onClose={onWarningClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Observera
          </AlertDialogHeader>

          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onWarningClose}>Avbryt</Button>
            <Button colorScheme="blue" onClick={onConfirm} ml={3}>
              Skicka
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export { WarningDialog };

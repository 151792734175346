import React from 'react';
import NextLink from 'next/link';
import { Link as ChakraLink } from '@chakra-ui/react';

export function Link({ href, children, target, ...rest }) {
  return (
    <NextLink href={href} passHref target={target}>
      <ChakraLink as="span" {...rest}>
        {children}
      </ChakraLink>
    </NextLink>
  );
}

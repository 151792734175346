import React from 'react';
import { HStack, Text, Heading, Flex } from '@chakra-ui/react';

function Times() {
  return (
    <Flex direction={'column'} align="stretch" marginBottom={10}>
      <Heading as={'h4'} size="md" marginTop={4} marginBottom={2}>
        Måndagar <Text as="span">26 Aug – 25 Nov</Text>
      </Heading>
      <HStack>
        <Text>Nybörjare: </Text> <Text>18.00 - 19.15</Text>
      </HStack>
      <HStack>
        <Text>Fortsättning: </Text> <Text>19.25 - 20.40</Text>
      </HStack>
      <HStack alignItems={'flex-start'}>
        <Text>Uppehåll: </Text> <Text>28 okt (höstlov)</Text>
      </HStack>
      <Heading as={'h4'} size="md" marginBottom={2} marginTop={4}>
        Tisdagar <Text as="span">27 Aug – 26 Nov</Text>
      </Heading>
      <HStack>
        <Text>Avancerad: </Text> <Text>18.00 - 19.15</Text>
      </HStack>
      <HStack>
        <Text>Nivå 4: </Text> <Text>19.25 - 20.40</Text>
      </HStack>
      <HStack alignItems={'flex-start'}>
        <Text>Uppehåll: </Text>
        <Text>29 okt (höstlov)</Text>
      </HStack>
    </Flex>
  );
}

export { Times };

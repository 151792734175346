import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { Layout } from 'components/ui';
import { Info } from 'components/features/Info';
import { RegistrationForm } from 'components/features/RegistrationForm';

function Home() {
  return (
    <Layout>
      <Box maxWidth="900px" px={[8, 4]}>
        <SimpleGrid columns={[1, 2]} spacing={10}>
          <Info />
          <RegistrationForm />
        </SimpleGrid>
      </Box>
    </Layout>
  );
}

export default Home;

export function translateLevelToSwedish(level) {
  switch (level) {
    case 'level1':
      return 'Nybörjare';
    case 'level2':
      return 'Fortsättning';
    case 'level3':
      return 'Avancerad';
    case 'level4':
      return 'Nivå 4';
    default:
      return level;
  }
}

export function translateTypeToSwedish(dancerType) {
  if (dancerType === 'lead') {
    return 'Förare';
  } else if (dancerType === 'follower') {
    return 'Följare';
  } else {
    return dancerType;
  }
}

export { getAbsoluteUrl } from './getAbsoluteUrl';
export { isObject } from './isObject';
export {
  translateLevelToSwedish,
  translateTypeToSwedish,
} from './translateToSwedish';

export function emailCleanup(email) {
  return email.toLowerCase().trim();
}

import React from 'react';
import {
  Alert as AlertContainer,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';
import { oneOf, node, bool } from 'prop-types';

Alert.propTypes = {
  children: node.isRequired,
  status: oneOf(['error', 'success', 'warning', 'info']),
  hasCloseButton: bool,
  hasIcon: bool,
};

function Alert({
  children,
  status = 'info',
  hasCloseButton = false,
  hasIcon = true,
}) {
  const [dismiss, setDismiss] = React.useState(false);
  if (dismiss) {
    return true;
  }
  return (
    <AlertContainer status={status}>
      {hasIcon && <AlertIcon />}
      <AlertDescription paddingRight="25px">{children}</AlertDescription>
      {hasCloseButton && (
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={() => setDismiss(true)}
        />
      )}
    </AlertContainer>
  );
}

export { Alert };

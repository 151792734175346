import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

export function CardContainer(props) {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.700')}
      rounded={{ md: 'lg' }}
      shadow="base"
      overflow="hidden"
      {...props}
    />
  );
}

import { useReducer } from 'react';
const initialState = {
  initial: true,
  success: false,
  loading: false,
  error: null,
};

const TYPES = {
  RESET: 'RESET',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

function reducer(state, action) {
  switch (action.type) {
    case TYPES.RESET:
      return {
        ...initialState,
      };
    case TYPES.LOADING:
      return {
        ...state,
        initial: false,
        loading: true,
        success: false,
        error: null,
      };
    case TYPES.ERROR:
      return {
        ...state,
        initial: false,
        loading: false,
        success: false,
        error: action.payload,
      };
    case TYPES.SUCCESS:
      return {
        ...state,
        initial: false,
        loading: false,
        success: true,
        error: null,
      };
    default:
      return state;
  }
}

/**
 * SLE State stands for: success, loading and error state.
 * Many ui elements have this three things that we need to keep track off.
 */
export function useSLEState() {
  const [{ initial, error, success, loading }, dispatch] = useReducer(
    reducer,
    initialState
  );

  function setReset() {
    dispatch({ type: TYPES.RESET });
  }

  function setLoading() {
    dispatch({ type: TYPES.LOADING });
  }

  function setError(error) {
    dispatch({ type: TYPES.ERROR, payload: error });
  }

  function setSuccess() {
    dispatch({ type: TYPES.SUCCESS });
  }

  return {
    setSuccess,
    setLoading,
    setReset,
    setError,
    initial,
    error,
    success,
    loading,
  };
}

import fetch from 'isomorphic-unfetch';
import { getAbsoluteUrl } from '../helpers';

async function fetchHandler(response) {
  try {
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  } catch (err) {
    return Promise.reject(response?.body || response);
  }
}

export function apiClient(
  endpoint,
  { body, headers, config, method = 'GET' } = {}
) {
  const baseHeaders = { 'Content-Type': 'application/json' };

  const baseConfig = {
    method,
    ...config,
    headers: {
      ...baseHeaders,
      ...headers,
    },
  };

  if (body) {
    if (baseConfig.headers['Content-Type'] === 'application/json') {
      baseConfig.body = JSON.stringify(body);
    } else {
      baseConfig.body = body;
    }
  }

  return fetch(endpoint, baseConfig).then(fetchHandler);
}

/**
 * api client for making request on the server eg getSeverSideProps.
 * It also adds the origin url, since from the server we need to make absolute requests
 * The challenge is that in the server we have no cookie credentials
 * So, protected api will return 401. We take the client cooke and forward
 */
export function apiClientSSR(
  endpoint,
  req,
  { body, headers, config, method = 'GET' } = {}
) {
  const { origin } = getAbsoluteUrl({ req });
  const baseHeaders = {
    'content-type': 'application/json',
    cookie: req ? req?.headers?.cookie : undefined,
  };

  const baseConfig = {
    method,
    ...config,
    headers: {
      ...baseHeaders,
      ...headers,
    },
  };

  if (body) {
    baseConfig.body = JSON.stringify(body);
  }

  return fetch(`${origin}${endpoint}`, baseConfig).then(fetchHandler);
}

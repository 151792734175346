import { useEffect, useReducer } from 'react';
import { apiClient } from '../api-client';
import { apiReducer, getInitialState } from '../api-client/apiReducer';

/**
 *
 * @param endpoint
 * @param options
 * @returns {data, loading, error} {Object}
 */
function useApiQuery(endpoint, options) {
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(apiReducer, initialState);

  useEffect(() => {
    dispatch({ type: 'FETCHING' });
    apiClient(endpoint, { method: 'GET', ...options })
      .then((result) => {
        dispatch({ type: 'RECEIVED', payload: result });
      })
      .catch((error) => {
        dispatch({ type: 'ERROR', payload: error });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
}

export { useApiQuery };

export function getValidationMessages({ formEl, formData }) {
  const bodyData = Object.entries(formData);
  const dancerTypeInvalid =
    bodyData?.dancerType === undefined
      ? { dancerType: 'Please select an item in the list.' }
      : undefined;
  return Array.from(formData.keys()).reduce(
    (acc, key) => {
      acc[key] = formEl.elements[key].validationMessage;
      return acc;
    },
    { ...dancerTypeInvalid }
  );
}

import React from 'react';
import { Text, Box, VStack, Heading, HStack } from '@chakra-ui/react';
import { Link } from 'components/ui/Link';
import { MailToLink } from 'components/ui/MailToLink';

function Footer() {
  return (
    <HStack
      as="footer"
      backgroundColor="teal.200"
      marginTop="60px"
      height="200px"
      justifyContent="center"
      paddingX="24px"
      borderTop="1px solid"
      borderColor="teal.300"
    >
      <VStack
        w="100%"
        minHeight="100px"
        paddingBottom="40px"
        alignItems="center"
        maxWidth="900px"
      >
        <Box alignSelf="baseline">
          <Heading as="h3">Kontakt</Heading>
          <Text>
            » <MailToLink />
          </Text>
          {/* <Text>
            » Kontakta oss till <MailToLink /> för att ta bort din persondata
          </Text>*/}
          <Text>
            <Link href="/privacy">Integritetspolicy (engelsk text)</Link>
          </Text>
        </Box>
      </VStack>
      <Box>
        {' '}
        <Text>
          <Link target="_blank" href="https://pigment.se/">
            Med stöd av: Pigment Webbyrå
          </Link>
        </Text>
      </Box>
    </HStack>
  );
}

export { Footer };

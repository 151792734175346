export function getInitialState() {
  return {
    success: false,
    data: null,
    loading: false,
    error: null,
  };
}

export function getApiStatusEvents(dispatch) {
  /**
   * fetching dispatcher
   */
  function fetching() {
    dispatch({ type: 'FETCHING' });
  }

  /**
   * received dispatcher
   * @param payload
   */
  function receivedResult(payload) {
    dispatch({ type: 'RECEIVED', payload });
  }

  function receivedError(error) {
    dispatch({ type: 'ERROR', payload: error });
  }
  return {
    fetching,
    receivedResult,
    receivedError,
  };
}

export function apiReducer(state, action) {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...getInitialState(),
        success: false,
        loading: true,
      };
    case 'RECEIVED':
      return {
        success: true,
        loading: false,
        data: action.payload,
        error: null,
      };
    case 'ERROR':
      return {
        success: false,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return state;
  }
}

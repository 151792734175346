import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';

export function CardHeader({ children, action }) {
  return (
    <Flex
      align="center"
      justify="space-between"
      px="6"
      py="4"
      borderBottomWidth="1px"
    >
      <Heading as="h2" fontSize="lg">
        {children}
      </Heading>
      {action}
    </Flex>
  );
}

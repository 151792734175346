import { useReducer } from 'react';
import { apiClient } from '../api-client';
import { apiReducer, getInitialState } from '../api-client/apiReducer';

function useApiMutation(endpoint, options) {
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(apiReducer, initialState);

  /**
   * @param body {object} mutation/post body.  Should be valid object that can be
   * stringifies into json.
   */
  function doMutation(body) {
    dispatch({ type: 'FETCHING' });
    apiClient(endpoint, { method: 'POST', body, ...options })
      .then((result) => {
        dispatch({ type: 'RECEIVED', payload: result });
      })
      .catch((error) => {
        dispatch({ type: 'ERROR', payload: error });
      });
  }

  return [doMutation, state];
}

export { useApiMutation };

import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react';

function BoldText({ children }) {
  return (
    <Text as="span" fontWeight={700}>
      {children}
    </Text>
  );
}

function LevelsInfoModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text paddingRight={3}>
            Info om vilka krav som ställs för att gå på respektive nivå
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Text>
              <BoldText>Nybörjare:</BoldText> Inga krav ställs
            </Text>
            <Text>
              <BoldText>Fortsättning (mellan):</BoldText> Turer som du ska kunna
              göra Enchufa, Dile que no, Exhibela, El Sombrero, El dedo.
            </Text>
            <Text>
              <BoldText>Avancerad:</BoldText> Turer som du ska kunna göra
              Setenta , Vacila, Llévatala , Adiós (rotation), Teje con Alegría.
              Sombrero con vuelta
            </Text>
            <Text>
              <BoldText>Nivå 4:</BoldText> Att du har haft minst 60% närvaro på
              Avancerad nivå. Undantag för ”ny inkomna” som är duktiga på
              kubansk salsa.
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Stäng
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { LevelsInfoModal };

import React from 'react';
import { Box, Center, Flex, Heading, Text, Img, Stack } from '@chakra-ui/react';
import { Link } from 'components/ui/Link';

function Header() {
  return (
    <Box as="header">
      <Box
        width="100%"
        p={2}
        borderBottom="1px solid"
        borderColor="teal.300"
        px="24px"
        mb={6}
      >
        <Flex width="100%" justifyContent="center" alignItems="center">
          <Link href="/">
            <Img height="auto" width="100px" src="/logo.png" alt="Your logo" />
          </Link>
          <Heading as="h1">Salsagiro - Kubansk Salsa</Heading>
        </Flex>

        <Center>
          <Stack>
            <Text fontSize="xl">Välkommen till Mäster Olofsgården</Text>
            <Text fontSize="xl">
              Svartmangatan 6, G:a Stan -{' '}
              <Link href="https://www.google.com/maps/place/Svartmangatan+6,+111+29+Stockholm/@59.3246637,18.0670087,16z/data=!4m5!3m4!1s0x465f77e28c90f295:0xf2fb16d7ff5bec15!8m2!3d59.324661!4d18.0713861?hl=sv">
                Karta
              </Link>
            </Text>
          </Stack>
        </Center>
      </Box>
    </Box>
  );
}

export { Header };
